import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import './font-example.css';

class FontExamples extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteView:"detail",
      homePageName:"home-4",
      detailPageName:"FontExamples"      
    };
  }

  componentDidMount() {
    document.getElementsByTagName("HTML")[0].style.overflowY = "auto";
    document.getElementById("home-wrapper").style.left = "-100%";
    document.getElementById("root").style.left = "0%";
    document.getElementById("side-nav").style.right = "calc(100% + 15px)";

    console.log("Site View: " + this.state.siteView);
    console.log("Home Page ID: " + this.state.homePageName);
    console.log("Detail Page Component: " + this.state.detailPageName);
  }

  changeView = () => {
    this.props.history.push('/#home-4');
    document.getElementById('home-4').scrollIntoView();

    // Update Side Nav
    let sideNav = document.getElementById('side-nav');  

    sideNav.childNodes[0].classList.remove("active");
    sideNav.childNodes[1].classList.remove("active");
    sideNav.childNodes[2].classList.remove("active");
    sideNav.childNodes[3].classList.remove("active");

    sideNav.childNodes[3].classList.add("active");
  }
  
  render() {
    return (
      <div id="detail-wrapper">
        {/* Change the HEAD meta data for this page */}
        <Helmet>
          <title>Interactive Matt | Font Examples</title>
        </Helmet>

        <button onClick={this.changeView}>Home</button>

        <h1>Lato Web 2.0 Font Weights</h1>

        <br />

        <p className="TestWeight_Hairline" >Lato Web - Hairline</p>
        <p className="TestWeight_Hairline_i" >Lato Web - Hairline Italic</p>

        <br />

        <p className="TestWeight_Thin" >Lato Web - Thin</p>
        <p className="TestWeight_Thin_i" >Lato Web - Thin Italic</p>

        <br />

        <p className="TestWeight_Light" >Lato Web - Light</p>
        <p className="TestWeight_Light_i" >Lato Web - Light Italic</p>

        <br />

        <p className="TestWeight_Regular" >Lato Web - Regular</p>
        <p className="TestWeight_Italic" >Lato Web - Italic</p>

        <br />

        <p className="TestWeight_Medium" >Lato Web - Medium</p>
        <p className="TestWeight_Medium_i" >Lato Web - Medium Italic</p>

        <br />

        <p className="TestWeight_Semibold" >Lato Web - Semibold</p>
        <p className="TestWeight_Semibold_i" >Lato Web - Semibold Italic</p>

        <br />

        <p className="TestWeight_Bold" >Lato Web - Bold</p>
        <p className="TestWeight_Bold_i" >Lato Web - Bold Italic</p>

        <br />

        <p className="TestWeight_Heavy" >Lato Web - Haavy</p>
        <p className="TestWeight_Heavy_i" >Lato Web - Heavy Italic</p>

        <br />

        <p className="TestWeight_Black" >Lato Web - Black</p>
        <p className="TestWeight_Black_i" >Lato Web - Black Italic</p>
      </div>
    );
  }
}

export default FontExamples;
