import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import './detail-one.css';

class DetailOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteView:"detail",
      homePageName:"home-1",
      detailPageName:"DetailOne"
    };
  }

  componentDidMount() {    
    document.getElementsByTagName("HTML")[0].style.overflowY = "auto";
    document.getElementById("home-wrapper").style.left = "-100%";
    document.getElementById("root").style.left = "0%";
    document.getElementById("side-nav").style.right = "calc(100% + 15px)";
    
    console.log("Site View: " + this.state.siteView);
    console.log("Home Page ID: " + this.state.homePageName);
    console.log("Detail Page Component: " + this.state.detailPageName);
  }

  changeView = () => {
    this.props.history.push('/#home-1');
    document.getElementById('home-1').scrollIntoView();

    // Update Side Nav
    let sideNav = document.getElementById('side-nav');  

    sideNav.childNodes[0].classList.remove("active");
    sideNav.childNodes[1].classList.remove("active");
    sideNav.childNodes[2].classList.remove("active");
    sideNav.childNodes[3].classList.remove("active");

    sideNav.childNodes[0].classList.add("active");
  }

  render() {
    return (
      <div id="detail-wrapper">
        {/* Change the HEAD meta data for this page */}
        <Helmet>
          <title>Interactive Matt | Detail One</title>
        </Helmet>

        <button onClick={this.changeView}>Home</button>

        <h1>Detail One</h1>
      </div>
    );
  }
}

export default DetailOne;
