import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

import DetailDefault from './pages/detail-default';
import DetailOne from './pages/detail-one';
import DetailTwo from './pages/detail-two';
import DetailThree from './pages/detail-three';
import FontExamples from './pages/font-examples';

import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        {/* Switcher to only render the first match for the URL */}
        {/* Render a page (HTML Structure) to the dom, based off of current URL */}
        <Switch>
          <Route exact path="/"><DetailDefault /></Route>

          <Route path="/detail-one" render={ props => <DetailOne {...props} /> } />

          <Route path="/detail-two" render={ props => <DetailTwo {...props} /> } />

          <Route path="/detail-three" render={ props => <DetailThree {...props} /> } />

          <Route path="/font-examples" render={ props => <FontExamples {...props} /> } />
          
          {/* Redirect the page to the Home Page if it does not match the list above */}
          <Redirect to="/" />
        </Switch>

        <div id="home-wrapper">
          <div id="home-1" className="section">
            {/* Link to Detail One page */}
            <Link to="/detail-one" tabIndex="-1">Detail One Link</Link>
          </div>
          <div id="home-2" className="section">
            {/* Link to Detail Two page */}
            <Link to="/detail-two" tabIndex="-1">Detail Two Link</Link>
          </div>
          <div id="home-3" className="section">
            {/* Link to Detail Three page */}
            <Link to="/detail-three" tabIndex="-1">Detail Three Link</Link>
          </div>
          <div id="home-4" className="section">
            {/* Link to Font Examples page */}
            <Link to="/font-examples" tabIndex="-1">Font Examples Link</Link>
          </div>
        </div>

        {/* Side Nav Inidicator buttons */}
        <div id="side-nav">
          <a href="/#home-1">1</a>
          <a href="/#home-2">2</a>
          <a href="/#home-3">3</a>
          <a href="/#home-4">4</a>
        </div>
      </Router>
    );
  }
}

export default App;
