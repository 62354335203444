import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import './detail-default.css';

class DetailDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteView:"overview",
      homePageName:"home-3",
      detailPageName:"DetailDefault"
    };
  }

  componentDidMount() {
    document.getElementsByTagName("HTML")[0].style.overflowY = "hidden";
    document.getElementById("home-wrapper").style.left = "0%";
    document.getElementById("root").style.left = "100%";
    document.getElementById("side-nav").style.right = "calc(0% + 15px)";
  }

  render() {
    return (
      <div id="detail-wrapper">
        {/* Change the HEAD meta data for this page */}
        <Helmet>
          <title>Interactive Matt | Interactive Visual Designer</title>
        </Helmet>

        <h1>Detail Default</h1>
      </div>
    );
  }
}

export default DetailDefault;
